<template>
  <div id="app">
    <div class="device">
      <div v-if="typeId.indexOf('smartElectricity')!=-1" class="container">
        <div v-for="(item,index) in wisdomElectricity" :key="index" class="content" @click="switchChart(item)">
          <div style="width:100%;display:flex">
            <!-- imageList.url2 -->
            <div>
              <!-- <img style="width:50px" :src="imageList.url1"> -->
              <img style="width:50px;" :src="item.nameList[0].indexOf('设备')!=-1?iconList.shebei:
                                             item.nameList[0].indexOf('井盖')!=-1?iconList.jingai:
                                             item.nameList[0].indexOf('压力')!=-1?iconList.yali:
                                             item.nameList[0].indexOf('功率')!=-1?iconList.gonglv:
                                             item.nameList[0].indexOf('电压')!=-1?iconList.dianya:
                                             item.nameList[0].indexOf('电流')!=-1?iconList.dianliu:
                                             item.nameList[0].indexOf('烟感')!=-1?iconList.yangan:
                                             item.nameList[0].indexOf('信号')!=-1?iconList.xinhao:
                                             item.nameList[0].indexOf('电池')!=-1?iconList.dianchidianya:iconList.shebei
              ">
            </div>
            <div class="box">
              <div v-for="(items,index) in item.nameList">{{items}}:</div>
            </div>
            <div class="box" style="overflow:hidden">
              <div v-for="(items,index) in item.valueList">{{items}}</div>
            </div>
            <el-popover v-if="item.nameList.length>2" class="xiangqing" placement="bottom" width="300" trigger="click">
              <div style="width:100%;display:flex;height:160px;overflow:scroll;line-height:30px">
                <div class="box">
                  <div v-for="(item,index) in itemList.nameList">{{item}}:</div>
                </div>
                <div class="box">
                  <div v-for="(item,index) in itemList.valueList" style="margin-left:80px">{{item}}</div>
                </div>
              </div>
              <!-- <span slot="reference">详情</span> -->
            </el-popover>
          </div>
        </div>
      </div>

      <div v-else class="container">
        <div v-for="(item,index) in showDeviceInfoList" :key="index" class="content">
          <div style="width:100%;display:flex;overflow:hiddden" @click="switchChart(item)">
            <div style="padding-right:20px">
              <img style="width:50px;" :src="item.nameList[0].indexOf('设备')!=-1?iconList.shebei:
                                             item.nameList[0].indexOf('井盖')!=-1?iconList.jingai:
                                             item.nameList[0].indexOf('压力')!=-1?iconList.yali:
                                             item.nameList[0].indexOf('功率')!=-1?iconList.gonglv:
                                             item.nameList[0].indexOf('电压')!=-1?iconList.dianya:
                                             item.nameList[0].indexOf('电流')!=-1?iconList.dianliu:
                                             item.nameList[0].indexOf('烟感')!=-1?iconList.yangan:
                                             item.nameList[0].indexOf('信号')!=-1?iconList.xinhao:
                                             item.nameList[0].indexOf('电池')!=-1?iconList.dianchidianya:iconList.shebei
              ">
            </div>
            <div>
              <div v-if="item.nameList.length>2&&item.nameList[0].indexOf('状态')==-1">{{item.nameList[0]}}: {{item.valueList[0]}}</div>
              <div v-if="item.nameList.length>2&&item.nameList[0].indexOf('状态')!=-1">{{item.nameList[1]}}: {{item.valueList[1]}}</div>
              <div v-if="item.nameList.length<=2">
                <div v-for="(items ,index) in item.nameList">
                  <div v-if="items.indexOf('状态')==-1">{{items}}: {{item.valueList[index]}}</div>
                </div>
              </div>
              <div v-for="(items ,index) in item.nameList">
                <div v-if="items.indexOf('状态')!=-1">{{items}}: {{item.valueList[index]}}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- echarts -->
      <div class="content-charts">
        <div>
          <div v-if="isShowTu" class="contant-next">
            <line-chart v-if="isShowTu" :dateList="dateList" :valueList="value1List" :hValue="hValue" :lValue="lValue" :chartTitle="chartTitle" :unit="unit"></line-chart>
          </div>
          <div v-else class="tips">
            暂无数据
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import deviceInfo from "../../api/manageApi/DeviceInfoManagement";

import lineChart from "@/components/echarts/lineChart";

export default {
  name: "DeviceValue",
  props: ['massages'],
  components: {
    lineChart,
  },
  data() {
    return {
      itemList: [],
      isShow: false,
      valueList: this.massages,
      deviceStatus: "正常",//设备状态
      //设备动态属性
      deviceValueInfoList: [],

      wisdomElectricity: [],

      typeId: '',


      imageList: {
        url1: require('../../assets/img/voltage.png'),
        url2: require('../../assets/img/wendu.png'),
      },

      iconList: {
        yangan: require("../../assets/img/icon1/yangan.png"),
        yali: require("../../assets/img/icon1/yali.png"),
        xinhao: require("../../assets/img/icon1/xinhao.png"),
        shebei: require("../../assets/img/icon1/shebei.png"),
        jingai: require("../../assets/img/icon1/jingai1.png"),
        gonglv: require("../../assets/img/icon1/gonglv.png"),

        dianya: require("../../assets/img/icon1/dianya.png"),
        dianliu: require("../../assets/img/icon1/dianliu.png"),
        dianchidianya: require("../../assets/img/icon1/dianchidianya.png"),
      },




      proList: [],//动态属性
      dateList: [], //折线图时间显示数组
      value1List: [], //折线图值显示数组
      hValue: "", //折线图高阈值显示
      lValue: "", //折线图低阈值显示
      chartTitle: "",//折线图标题
      unit: "",//折线图单位显示
      isShowTu: false,//是否显示折线图,
      showChartDeviceId: "",//显示折线图的设备id
      showChartDeviceTypeId: "",//显示折线图的设备类型id

      showDeviceInfoList: [],//显示设备详情集合

    }
  },
  created() {
    this.showInfo()
    this.showCharts(this.valueList)
  },



  methods: {
    showInfo() {
      this.typeId = this.valueList.typeId;

      //如果是智慧用电相关设备，对数据处理
      if (this.typeId.indexOf("smartElectricity") != -1) {
        deviceInfoManagement.getDeviceInfoValueByDeviceId(this.valueList.id)
          .then((res) => {
            this.deviceValueInfoList = res.data.data.list;
            this.wisdomElectricityInfo()
            console.log(this.deviceValueInfoList);
          });
      }
      deviceInfoManagement.getDeviceInfoByDeviceId(this.valueList.id, 1, 1)
        .then((res) => {
          this.deviceValueInfoList = []
          // console.log(res.data.data.deviceDynamicVo)
          let lastDataTime = res.data.data.deviceDynamicVo["lastDataTime"]
          let createDeviceTime = res.data.data.deviceDynamicVo["createDeviceTime"]
          console.log(lastDataTime)
          console.log(createDeviceTime)
          let isOnline = true;
          if (lastDataTime == null || createDeviceTime > lastDataTime) {
            isOnline = false;
          }
          for (const i in res.data.data.deviceDynamicVo["nameList"]) {
            let obj = {}
            obj.id = res.data.data.deviceDynamicVo["nameList"][i]
            obj.value = res.data.data.deviceDynamicVo["valueList"][i]
            this.deviceValueInfoList.push(obj)
          }
          //其它类型设备走下面正常处理
          let typeMap = new Map([
            ["井盖", { name: [], value: [] }],
            ["压力", { name: [], value: [] }],
            ["电池", { name: [], value: [] }],
            ["水位", { name: [], value: [] }],
          ]);
          let typeList = ["井盖", "压力", "电池", "水位"]
          this.showDeviceInfoList = []//清空数据
          let deviceStatus = ""
          let nameList = []
          let valueList = []
          let obj = {}
          for (const i in this.deviceValueInfoList) {
            nameList = []
            valueList = []
            if (typeMap.has(this.deviceValueInfoList[i]["id"].substring(0, 2))) {
              typeMap.get(this.deviceValueInfoList[i]["id"].substring(0, 2))["name"].push(this.deviceValueInfoList[i]["id"])
              typeMap.get(this.deviceValueInfoList[i]["id"].substring(0, 2))["value"].push(this.deviceValueInfoList[i]["value"])
            } else if (this.deviceValueInfoList[i]["id"].indexOf("状态") != -1
              && this.deviceValueInfoList[i]["value"] != ""
              && ((this.deviceValueInfoList[i]["value"] == "正常" && deviceStatus == "") || (this.deviceValueInfoList[i]["value"] != "正常"))
              && this.deviceValueInfoList[i]["value"] != null
              && this.deviceValueInfoList[i]["value"] != undefined
            ) {
              deviceStatus = this.deviceValueInfoList[i]["value"]
            } else if ((this.deviceValueInfoList[i]["id"].indexOf("值") != -1
              || this.deviceValueInfoList[i]["id"].indexOf("压力") != -1
              || this.deviceValueInfoList[i]["id"].indexOf("电流") != -1
              || this.deviceValueInfoList[i]["id"].indexOf("电压") != -1
              || this.deviceValueInfoList[i]["id"].indexOf("角度") != -1
              || this.deviceValueInfoList[i]["id"].indexOf("电量") != -1
              || this.deviceValueInfoList[i]["id"].indexOf("功率") != -1
              || this.deviceValueInfoList[i]["id"].indexOf("用水") != -1
              || this.deviceValueInfoList[i]["id"].indexOf("用电") != -1
            )
              && this.deviceValueInfoList[i]["id"].indexOf("状态") == -1
              && this.deviceValueInfoList[i]["id"].indexOf("记录") == -1
              && this.deviceValueInfoList[i]["id"].indexOf("时间") == -1) {
              nameList.push(this.deviceValueInfoList[i]["id"])
              if (this.deviceValueInfoList[i]["value"] == ""
                || this.deviceValueInfoList[i]["value"] == null
                || this.deviceValueInfoList[i]["value"] == undefined) {
                valueList.push("暂无")
              } else {
                valueList.push(this.deviceValueInfoList[i]["value"])
              }
              obj["nameList"] = nameList
              obj["valueList"] = valueList
              this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)))
            }
          }
          nameList = []
          valueList = []
          if (deviceStatus != "" && isOnline == true) {
            nameList.push("设备状态")
            valueList.push(deviceStatus)
          } else {
            if (this.valueList.id.indexOf("IOE") != -1
              || this.valueList.id.indexOf("_") != -1
              || this.typeId == "DXHippoAcoustoOptic"
              || this.typeId == "numPressureGauge") {
              if (deviceStatus != "") {
                nameList.push("设备状态")
                valueList.push(deviceStatus)
              } else {
                nameList.push("设备状态")
                valueList.push("正常")
              }
            } else {
              nameList.push("设备状态")
              valueList.push("未服务")
              isLost = true
            }
          }
          console.log(nameList)
          console.log(valueList)
          obj["nameList"] = nameList
          obj["valueList"] = valueList
          this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)))
          for (const i in typeList) {
            obj = {}
            if (typeMap.get(typeList[i]).name.length != 0) {
              let status = ""
              nameList = []
              valueList = []
              for (const ii in typeMap.get(typeList[i]).name) {
                if (typeMap.get(typeList[i]).name[ii].indexOf("状态") != -1
                  && typeMap.get(typeList[i]).value[ii] != ""
                  && ((typeMap.get(typeList[i]).value[ii] == "正常" && status == "") || (typeMap.get(typeList[i]).value[ii] != "正常"))
                  && typeMap.get(typeList[i]).value[ii] != null
                  && typeMap.get(typeList[i]).value[ii] != undefined) {
                  status = typeMap.get(typeList[i]).value[ii]
                } else if ((typeMap.get(typeList[i]).name[ii].indexOf("值") != -1
                  || typeMap.get(typeList[i]).name[ii].indexOf("压力") != -1
                  || typeMap.get(typeList[i]).name[ii].indexOf("电流") != -1
                  || typeMap.get(typeList[i]).name[ii].indexOf("电压") != -1
                  || typeMap.get(typeList[i]).name[ii].indexOf("角度") != -1
                  || typeMap.get(typeList[i]).name[ii].indexOf("电量") != -1)
                  && typeMap.get(typeList[i]).name[ii].indexOf("状态") == -1
                  && typeMap.get(typeList[i]).name[ii].indexOf("记录") == -1
                  && typeMap.get(typeList[i]).name[ii].indexOf("时间") == -1) {
                  nameList.push(typeMap.get(typeList[i]).name[ii])
                  if (typeMap.get(typeList[i]).value[ii] == ""
                    || typeMap.get(typeList[i]).value[ii] == null
                    || typeMap.get(typeList[i]).value[ii] == undefined) {
                    valueList.push("暂无")
                  } else {
                    valueList.push(typeMap.get(typeList[i]).value[ii])
                  }
                }
              }
              if (status != "") {
                nameList.push("状态")
                valueList.push(status)
              } else {
                // nameList.push("状态")
                // valueList.push("未服务")
              }
              console.log(nameList)
              console.log(valueList)
              obj["nameList"] = nameList
              obj["valueList"] = valueList
              this.showDeviceInfoList.push(JSON.parse(JSON.stringify(obj)))
            }
          }
          //   console.log(this.showDeviceInfoList)
        })
    },
    wisdomElectricityInfo() {
      this.wisdomElectricity = []
      let num = 1;
      let obj = {}
      let nameList = []
      let valueList = []
      let status = ""
      let type = ""
      for (let i = 0; i < this.deviceValueInfoList.length; i++) {
        if (this.deviceValueInfoList[i].id.indexOf("CH") != -1) {
          if (this.deviceValueInfoList[i].id.indexOf("CH" + num) != -1) {
            if (this.deviceValueInfoList[i].id.indexOf("通道类型") != -1) {
              type = this.deviceValueInfoList[i].value
            } else if (this.deviceValueInfoList[i].id.indexOf("报警") == -1
              && this.deviceValueInfoList[i].id.indexOf("故障") == -1
              && this.deviceValueInfoList[i].id.indexOf("失联") == -1
              && this.deviceValueInfoList[i].id.indexOf("开关状态") == -1) {
              if (this.deviceValueInfoList[i].value == "") {
                nameList.push(this.deviceValueInfoList[i].id)
                valueList.push("暂无")
              } else {
                nameList.push(this.deviceValueInfoList[i].id)
                valueList.push(this.deviceValueInfoList[i].value)
              }
            } else {
              status = this.deviceValueInfoList[i].value
            }
          } else {
            for (let ii = 0; ii < nameList.length; ii++) {
              if (nameList[ii].indexOf("检测值") != -1 && type != "") {
                obj["switchName"] = nameList[ii]
                nameList[ii] = nameList[ii].replace("检测", type);
              }
            }
            if (status != "" && status != null && status != undefined) {
              nameList.push("设备状态")
              valueList.push(status)
            } else {
              nameList.push("设备状态")
              valueList.push("离线")
            }
            obj['nameList'] = nameList
            obj['valueList'] = valueList
            let obj11 = JSON.parse(JSON.stringify(obj))
            this.wisdomElectricity.push(obj11)
            obj = {}
            nameList = []
            valueList = []
            num++;
            if (this.deviceValueInfoList[i].id.indexOf("通道类型") != -1) {
              type = this.deviceValueInfoList[i].value
            } else if (this.deviceValueInfoList[i].id.indexOf("报警") == -1
              && this.deviceValueInfoList[i].id.indexOf("故障") == -1
              && this.deviceValueInfoList[i].id.indexOf("失联") == -1
              && this.deviceValueInfoList[i].id.indexOf("开关状态") == -1
              && this.deviceValueInfoList[i].value != "") {
              nameList.push(this.deviceValueInfoList[i].id)
              valueList.push(this.deviceValueInfoList[i].value)
            } else {
              status = this.deviceValueInfoList[i].value
            }
          }
          if (i == this.deviceValueInfoList.length - 1) {
            for (let ii = 0; ii < nameList.length; ii++) {
              if (nameList[ii].indexOf("检测值") != -1 && type != "") {
                obj["switchName"] = nameList[ii]
                nameList[ii] = nameList[ii].replace("检测", type);
              }
            }
            if (status != "" && status != null && status != undefined) {
              nameList.push("设备状态")
              valueList.push(status)
            } else {
              nameList.push("设备状态")
              valueList.push("离线")
            }
            obj['nameList'] = nameList
            obj['valueList'] = valueList
            let obj11 = JSON.parse(JSON.stringify(obj))
            this.wisdomElectricity.push(obj11)
          }
        }
      }
    },

    showCharts(row) {
      this.proList = [];
      this.chartTitle = "";
      this.isShowTu = false
      this.showCharDeviceId = row.id
      this.showChartDeviceTypeId = row.typeId
      deviceInfo.getDynamicPro(row.typeId)
        .then((res) => {
          let _this = this;
          for (const i in res.data.data.list) {
            if ((res.data.data.list[i].indexOf("值") != -1
              || res.data.data.list[i].indexOf("电压") != -1
              || res.data.data.list[i].indexOf("电流") != -1
            )
              && res.data.data.list[i].indexOf("状态") == -1
              && res.data.data.list[i].indexOf("阈值") == -1) {
              this.proList.push(res.data.data.list[i]);
            }
          }
          if (this.proList.length == 1 || this.proList.length > 1) {
            deviceInfo.getDayValue(
              row.id,
              "",
              "",
              row.typeId,
              row.id + ".value." + this.proList[0]
            ).then((res) => {
              if (res.data.message == "不显示") {
                _this.isShowTu = false;
              } else {
                _this.dateList = res.data.data.dateList;
                _this.value1List = res.data.data.valueList;
                if (res.data.data.hValue != undefined && res.data.data.hValue != null) {
                  _this.hValue = res.data.data.hValue;
                  _this.lValue = res.data.data.lValue;
                }
                if (res.data.data.unit != "" && res.data.data.unit != undefined) {
                  this.unit = res.data.data.unit
                }
                this.chartTitle = this.proList[0]
                _this.isShowTu = true
              }
            })
          }
        })
    },



    //切换显示的折线图
    switchChart(item) {
      console.log(item);
      if (item.switchName) {
        item = item["switchName"]
      } else {
        if (item.nameList[0] == '设备状态') {
          this.$message.warning("无设备状态数据！")
          return
        } else {
          item = item.nameList[0]

        }
      }

      this.isShowTu = false;
      this.chartTitle = item
      deviceInfo.getDayValue(
        this.showCharDeviceId,
        "",
        "",
        this.showChartDeviceTypeId,
        this.showCharDeviceId + ".value." + item
      ).then((res) => {
        if (res.data.message == "不显示") {
          this.isShowTu = false;
        } else {
          this.dateList = res.data.data.dateList;
          this.value1List = res.data.data.valueList;
          if (res.data.data.hValue != undefined && res.data.data.hValue != null) {
            this.hValue = res.data.data.hValue;
            this.lValue = res.data.data.lValue;
          }
          if (res.data.data.unit != "" && res.data.data.unit != undefined) {
            this.unit = res.data.data.unit
          }
          this.isShowTu = true
        }
      })
    },


  }

}
</script>

<style lang="scss" scoped>
.staticValBox {
  width: 100%;
  font-size: 16px;
}
.device {
  display: flex;
}
.container {
  width: 35%;
  height: 440px;
  overflow: scroll;

  .content {
    cursor: pointer;
    position: relative;
    margin: 10px 0;
    box-sizing: border-box;
    padding: 20px 0;
    display: flex;
    width: 95%;
    height: 100px;
    line-height: 28px;
    box-shadow: 1px 1px 2px #ccc;
    overflow: hidden;
    .box:nth-child(1) {
      width: 64%;
      height: 50px;
      overflow: scroll;
    }
    .box:nth-child(2) {
      margin-left: 20px;
      width: 36%;
      height: 50px;
      overflow: hidden;
    }
    .content1 {
      width: 100%;
      display: flex;
      font-size: 16px;
      overflow: hidden;
      box-shadow: 1px 1px 2px #ccc;

      span:nth-child(1) {
        width: 50%;
        // border: 1px solid red;
        display: inline-block;
        font-weight: 600;
      }
      span:nth-child(2) {
        width: 50%;
        display: inline-block;
        // border: 1px solid pink;
      }
      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}
.content-charts {
  width: 65%;
  padding: 0 10px;
  margin-left: 20px;
  box-sizing: border-box;
  box-shadow: -1px -1px 2px #ccc;
}

.tips {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-top: 100px;
  color: rgb(171, 171, 171);
}
.boxes {
  width: 500px;
  height: 500px;
}
.xiangqing {
  position: absolute;
  bottom: 5px;
  right: 15px;
  cursor: pointer;
  color: gray;
}
// .xiangqing::after {
//   content: " >>";
// }
.xiangqing:hover {
  color: rgb(91, 195, 236);
}
.contant-next {
  margin-top: 40px;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(122, 184, 247, 0.4);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(122, 184, 247, 0.4);
}

// 滚动条两端的按钮
::-webkit-scrollbar-button {
  display: none;
}
</style>