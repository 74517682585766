<template>
  <div id="app">

    <!-- 静态属性 -->
    <div class="container">

      <div class="content-left">
        <div>
          <div class="staticValBox">
            <span class="titles">设备编号：</span>
            <span>{{list.id}}</span>
          </div>
          <div class="staticValBox">
            <span class="titles">设备类型：</span>
            <span>{{list.typeName}}</span>
          </div>
        </div>

        <div class="staticValBox" v-for="item in list.device" v-if="item.proName.indexOf('绑定视频')==-1">
          <span class="titles">{{item.proName}}：</span>
          <span>{{item.value }}</span>
        </div>

      </div>
      <div class="content-right">
        <maps :positions="positions"></maps>
      </div>

    </div>

  </div>
</template>

<script>
// 查询单个地图设备
import deviceTypeAboutMap from '@/api/manageApi/DeviceTypeAboutMap'

import MapIocli from '@/components/cloudPlatform/Center/deviceMap'
import companySystem from "@/api/managementApi/CompanyInfo";
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";

export default {
  components: {
    maps: MapIocli,
  },
  name: 'Device',
  props: ['info'],
  data() {
    return {
      list: [],
      companyNameById: {},
      systemNameById: {},
      permissionsSystemName: [],

      deviceId: '',

      positions: {
        lng: '',
        lat: '',
      }
    }
  },
  created() {
    this.list = this.info
    console.log(this.list);
    this.deviceId = this.list.id


    this.getCompanyInfo()
    this.jurisSysName()

    this.getDeviceType()
  },

  methods: {
    // 所有公司
    async getCompanyInfo() {
      const { data: res } = await companySystem.getCompanyInfo()
      for (let i = 0; i < this.list.device.length; i++) {
        if (this.list.device[i].proName == "布防状态") {
          if (this.list.device[i].value == "1") {
            this.list.device[i].value = "布防"
          } else if (this.list.device[i].value == "0") {
            this.list.device[i].value = "撤防"
          }
        }
        if (this.list.device[i].proName == "绑定公司") {
          for (let j = 0; j < res.data.list.length; j++) {
            if (this.list.device[i].value == res.data.list[j].id) {
              this.list.device[i].value = res.data.list[j].name
            }
          }
        }
      }
    },

    // 获取权限系统名称
    async jurisSysName() {
      const { data: res } = await noticeSysManagement.getNotifyBigSystemById()
      for (let i = 0; i < this.list.device.length; i++) {
        if (this.list.device[i].proName == "所属系统") {
          for (let j = 0; j < res.data.list.length; j++) {
            if (this.list.device[i].value == res.data.list[j].id) {
              this.list.device[i].value = res.data.list[j].bigSystemName
            }
          }
        }
      }
    },

    async getDeviceType() {
      console.log(this.deviceId);
      const { data: res } = await deviceTypeAboutMap.getOneDeviceTypeAboutMap(this.deviceId);
      console.log(res);
      if (res.data.list.length == 0) {
        //   this.$message.warning('该设备尚未添加位置信息！')
        return
      } else {
        this.positions.lng = res.data.list[0].lng;
        this.positions.lat = res.data.list[0].lat;
        console.log(this.positions);
      }
    }






  },

}
</script>

<style lang="scss" scoped>
.staticValBox {
  width: 100%;
  font-size: 16px;
  padding: 5px 0;
  span:nth-child(1) {
    display: inline-block;
    width: 28%;
    padding: 10px 10px;
  }
}
.titles {
  line-height: 25px;
  font-weight: 600;
}
.container {
  display: flex;
  height: 430px;
  .content-left {
    box-sizing: border-box;
    width: 50%;
    margin: 0 5px;

    overflow: scroll;
  }
  .content-right {
    width: 50%;
    border-radius: 15px;
  }
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(122, 184, 247, 0.4);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(122, 184, 247, 0.4);
}

// 滚动条两端的按钮
::-webkit-scrollbar-button {
  display: none;
}
</style>