<template>
  <div id="app">
    <div class="item" style="display:flex;margin:10px 0">

      <div class="item">
        <el-input @keyup.enter.native="getDeviceInfoList1" placeholder="请输入设备id" v-model="deviceInfoQuery.id"></el-input>
      </div>

      <div class="item">
        <el-select v-model="deviceTypeName" filterable placeholder="请选择设备类型" clearable @change="selectDeviceType">
          <el-option v-for="item in getAllDeviceTypeList" :key="item.id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </div>

      <div class="item">
        <el-cascader placeholder="请选择单位" filterable :show-all-levels="false" clearable ref="cascader1"
          v-model="companyName" :options="allCompanyList" @change="checkedCompany1" :props="defaultProps">
        </el-cascader>
      </div>

      <div class="item">
        <el-button type="primary" size="default" @click="getDeviceInfoList1()">查询</el-button>
      </div>

      <div class="item">
        <el-button type="success" size="default" @click="handelAdd" v-if="userRightList.addJuris == '1'">添加设备</el-button>
      </div>

      <div class="item">
        <el-upload :show-file-list="false" :before-upload="beforeUpload" :on-success="onSuccess" :on-error="onError"
          :action="baseUrl + 'iotechserver/deviceInfoExcle/excel/excelImport'" multiple
          v-if="userRightList.addJuris == '1'">
          <el-button :icon="importData.iconImport" :disabled="importData.disabledImport" type="success"
            class="headerButton">{{ importData.butTextImport }}</el-button>
        </el-upload>
      </div>
      <div class="item">
        <el-button :icon="importData.iconExport" :disabled="importData.disabledExport" type="success" class="headerButton"
          @click="exportAll">{{ importData.butTextExport }}
        </el-button>
      </div>
      <div class="item" style="margin-left:auto">
        <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
          <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
      </div>

    </div>


    <el-row :gutter="20">
      <el-col :span="24" :offset="0">
        <el-table v-loading="loading" :data="deviceInfoList" style="width: 100%" :stripe="true" :border="true"
          highlight-current-row>
          <el-table-column type="index" label="序号" align="center" width="70"></el-table-column>
          <el-table-column v-if="tableTitle.includes('设备编号')" prop="id" label="设备编号" align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('设备类型')" prop="typeName" label="设备类型"
            align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('安装位置')" prop="addressValue" label="安装位置"
            align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('单位')" prop="companyValue" label="单位"
            align="center"></el-table-column>
            <el-table-column v-if="tableTitle.includes('创建时间')" prop="createTime" label="创建时间"
            align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('状态')" label="状态" align="center">
           <template slot-scope="scope">
             {{ scope.row.status }}
              </template>
             </el-table-column>
            
          <el-table-column fixed="right" label="操作" width="480" align="center">
            <template slot-scope="scope">
              <el-popconfirm v-if="scope.row.typeId != 'video' && scope.row.typeId != 'VideoRTSP'"
                confirm-button-text="播放" @confirm="showVideo(scope.row)" cancel-button-text="绑定"
                @cancel="addNewBindVideo(scope.row)" icon="el-icon-info" icon-color="green" title="查看已绑定视频还是绑定新的视频设备？">
                <el-button slot="reference"
                  :type="scope.row.videoUrl != null || scope.row.isBandYSVideo || scope.row.typeId == 'YSVideo' || scope.row.typeId == 'VideoRTSP' || scope.row.isBandYSVideo ? 'success' : 'warning'"
                  size="small" style="margin-right: 11px">视频</el-button>
              </el-popconfirm>
              <el-button v-else slot="reference" @click="showVideo(scope.row)"
                :type="scope.row.videoUrl != null ? 'success' : 'warning'" size="small">视频</el-button>
              <el-button type="primary" size="small" @click="lookMore(scope.row)">查看</el-button>
              <el-button @click="handleEdit(scope.row)" type="primary" size="small"
                v-if="userRightList.updateJuris == '1'">编辑</el-button>
              <el-button @click="toHistory(scope.row)" type="primary" size="small">历史记录</el-button>
              <el-button @click="toCRT(scope.row)" type="primary" size="small">CRT</el-button>
              <el-button @click="handleRemove(scope.row.id, scope.row)" type="danger" size="small"
                v-if="userRightList.delJuris == '1'">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 详细信息 -->
    <!-- <div v-if="detailsIn">
      <el-dialog :title="titles" :visible.sync="detailsIn" width="50%" @close="closeDialog" :close-on-click-modal="false">
        <el-tabs v-model="activeName" @tab-click="switchTab()">
          <el-tab-pane label="基本信息" name="first">
            <div class="contant">
              <Device keep-alive :info="listInfo" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="设备详情" name="second">
            <div class="contant" v-if="showChart">
              <DeviceValue keep-alive :massages="listInfo" />
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
    </div> -->
    <!-- 添加设备的弹框 -->
    <el-dialog title="设备信息" :visible.sync="dialogVisible" width="40%" @close="clearDeviceInfo"
      :close-on-click-modal="false" style="top:0%;background:rgba(0,0,0,0.5)">
      <!-- 外层div -->
      <div style="height:370px;overflow:scroll;scrollbar-width:none">
        <!-- 表单部分 -->
        <el-form :model="deviceInfo" ref="form" label-width="120px" :inline="false" size="normal" :rules="rules">
          <!-- 输入id -->
          <el-form-item label="ID" prop="id" :disabled="addOrEditFlag == 'edit'">
            <el-input v-model="deviceInfo.id" :disabled="addOrEditFlag == 'edit'"></el-input>
          </el-form-item>
          <!-- v-if="setingMap" -->

          <!-- 选取坐标 -->
          <el-form-item label="选取坐标点">
            <el-button @click="addMapPosition">点击选择</el-button>
            <div style="display:inline;margin-right:40px;float:right">
              <span>是否布防：</span>
              <el-switch v-model="isInvoke" active-text="布防" inactive-text="撤防">
              </el-switch>
            </div>
          </el-form-item>

          <!-- 绑定公司 -->
          <el-form-item label="绑定公司" prop="typeId" v-if="!sxsbshlw">
            <el-cascader placeholder="请选择需要绑定的公司（默认 无）" :show-all-levels="false" clearable ref="cascader"
              v-model="checkedCom" :options="allCompanyList" @change="checkedCompany" :props="defaultProps" filterable
              style="width:100%">
            </el-cascader>
          </el-form-item>

          <!-- 设备类型 -->
          <el-form-item label="设备类型" prop="typeId">
            <el-select v-model="typeName" placeholder="请选择设备类型" @change="buiding" style="width:100%" filterable>
              <el-option v-for="item in deviceTypeNameList" :key="item.id" :label="item.name" :value="item">
              </el-option>
            </el-select>
            <div v-if="isDisplay" style="margin-right:40px;float:right">
              <span>是否同步添加AEP：</span>
              <el-switch v-model="deviceInfo.isSyncAdd">
              </el-switch>
            </div>
          </el-form-item>

          <el-form-item v-for="(item, index) in selectDeviceTypeInfo.properties" :key="index" :label="item" prop="value"
            v-show='item != "绑定公司" && item != "所属系统" && item != "设备图片" && item != "设备ID" && item != "布防状态"'>
            <el-input
              v-if='item != "微信通知" && item != "短信通知" && item != "语音通知" && item != "通知电话" && item.indexOf("绑定视频") == -1 && item != "布防状态"'
              v-model="deviceInfo.value[index]" :placeholder="item == '请填写' + item" :type="'text'"></el-input>
            <el-input v-if='item == "微信通知" || item == "短信通知" || item == "语音通知" || item == "通知电话"'
              v-model="deviceInfo.value[index]" placeholder="静态属性的值"></el-input>
            <el-input v-if='item.indexOf("绑定视频") != -1' readonly="readonly" v-model="deviceInfo.value[index]"
              placeholder="静态属性的值"></el-input>
          </el-form-item>

          <el-form-item label="所属系统" v-if="!sxsbshlw">
            <el-select v-model="checkedSys" clearable placeholder="请选择所属系统（默认 无）" style="width:100%"
              @change="checkedSystem" filterable >
              <el-option v-for="item in permissionsSystemName" :key="item.id" :label="item.bigSystemName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备图片" prop="val">
            <input id="deviceInfoIcon" type="file" ref="uploadInput" @change="fileChange" />
            <br>
            <span>上传图标只能是 JPG、PNG或SVG 格式,并且大小不超过1M</span>
            <img v-if="imgFlag" :src="deviceInfoIcon" style="width: 50px" />
            <img v-else :src="baseUrl + deviceInfoIcon" style="width: 50px" />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
         <el-button @click="dialogVisibleFun">取 消</el-button>  
        <el-button type="primary" @click="addOrEditDeviceInfo" :disabled="!typeName">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="添加设备位置信息" :visible.sync="deviceMap" width="40%">
      <div style="height:440px">
        <map-position :rowId="rowId" @send="send"></map-position>
      </div>
    </el-dialog>

    <!-- 绑定视频弹窗 -->
    <el-dialog title="绑定视频" :visible.sync="videoDialogVisible" width="25%" @close="clearDeviceStaticProperties"
      :close-on-click-modal="false">
      <el-form :model="deviceStaticProperties" ref="formVideo" label-width="120px" :inline="false" size="normal">
        <el-form-item label="绑定视频1">
          <el-select v-model="deviceStaticProperties.videoInfoId1" style="margin-left: 10px" clearable placeholder="请选择视频"
            >
            <el-option v-for="item in videoList" :key="item.id + '-1'" :label="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定视频2" >
          <el-select v-model="deviceStaticProperties.videoInfoId2" style="margin-left: 10px" clearable placeholder="请选择视频"
            >
            <el-option v-for="item in videoList" :key="item.id + '-2'" :label="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定视频3" >
          <el-select v-model="deviceStaticProperties.videoInfoId3" style="margin-left: 10px" clearable placeholder="请选择视频"
            >
            <el-option v-for="item in videoList" :key="item.id + '-3'" :label="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定视频4" >
          <el-select v-model="deviceStaticProperties.videoInfoId4" style="margin-left: 10px" clearable placeholder="请选择视频"
            >
            <el-option v-for="item in videoList" :key="item.id + '-4'" :label="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定视频5" >
          <el-select v-model="deviceStaticProperties.videoInfoId5" style="margin-left: 10px" clearable placeholder="请选择视频"
            >
            <el-option v-for="item in videoList" :key="item.id + '-5'" :label="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定视频6" >
          <el-select v-model="deviceStaticProperties.videoInfoId6" style="margin-left: 10px" clearable placeholder="请选择视频"
            >
            <el-option v-for="item in videoList" :key="item.id + '-6'" :label="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定视频7" >
          <el-select v-model="deviceStaticProperties.videoInfoId7" style="margin-left: 10px" clearable placeholder="请选择视频"
            >
            <el-option v-for="item in videoList" :key="item.id + '-7'" :label="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定视频8" >
          <el-select v-model="deviceStaticProperties.videoInfoId8" style="margin-left: 10px" clearable placeholder="请选择视频"
            >
            <el-option v-for="item in videoList" :key="item.id + '-8'" :label="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定视频9" >
          <el-select v-model="deviceStaticProperties.videoInfoId9" style="margin-left: 10px" clearable placeholder="请选择视频"
            >
            <el-option v-for="item in videoList" :key="item.id + '-9'" :label="item.id" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button @click="videoDialogVisible = false">取 消</el-button>  
        <el-button type="primary" @click="realBindVideo">绑定</el-button>
      </div>
    </el-dialog>
    <el-pagination :current-page.sync="current" :page-size="limit" :total="total"
      layout="total, prev, pager, next, jumper" style="padding-top: 10px;  text-align: center"
      @current-change="getDeviceInfoList"></el-pagination>

    <!-- 播放视频弹窗 -->
    <el-dialog title="视频" :visible.sync="playerDialogVisible" :destroy-on-close=true width="1280px" top="7vh"
      style="background:rgba(0,0,0,0.5)">
      <div class="videoClass" style="height:75.5vh;">
        <cus-player ref="video0"></cus-player>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import deviceMapPosition from '@/components/cloudPlatform/Center/deviceMapPosition'

import DeviceValue from '@/components/manage/DeviceValue'
import Device from '@/components/manage/Device'
import { export_json_to_excel } from "../../introduce/Export2Excel";
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";
import companySystem from "@/api/managementApi/CompanyInfo";
import dataStorage from '@/../utils/dataStorage.js'
import deviceInfo from "../../api/manageApi/DeviceInfoManagement";
import DeviceType from "../../api/manageApi/DeviceTypeManagement";
import crtDeviceCoordinate from "../../api/manageApi/CrtDeviceCoordinate";
import baseDate from "../../assets/config/BaseData"
import lineChart from "@/components/echarts/lineChart";
import axios from 'axios'
import { indexOf } from 'zrender/lib/core/util';
// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";
import { mapState } from 'vuex'


import QrCodeDeviceInfo from '@/api/qrCode/QrCodeDeviceInfo'
//视频组件
import CusPlayer from '../videos/CusPlayer.vue'
import FlvJsPlayer from "xgplayer-flv.js";
import Player from "xgplayer";
import { v4 } from 'uuid';
export default {
  components: {
    Device, DeviceValue,
    lineChart,
    mapPosition: deviceMapPosition,
    CusPlayer,
  },
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '设备编号',
      }, {
        id: 1,
        title: '设备类型',
      }, {
        id: 2,
        title: '安装位置',
      }, {
        id: 3,
        title: '单位',
      }, {
        id: 4,
        title: '状态',
      }],
      tableTitle: ['设备编号', '设备类型', '安装位置', '单位', '创建时间','状态'],


      deviceTypeName: '',   // 选择的设备类型
      companyName: '',  // 选择的公司名称
      isDisplay: false,//添加AEP选项默认隐藏
      newDeviceInfoList:[],

      //   setingMap: false,
      systemValue: '',
      companyValue: '',
      addressValue: '',
      deviceMap: false,
      rowId: '',
      showChart: false,
      listInfo: {}, // 当前行的设备信息
      titles: '',
      activeName: 'first',
      detailsIn: false,
      sxsbshlw: false,//所选设备类型是否是个人设备
      submitWindows: false,
      zbd: {
        lat: null,
        lng: null
      },//提交设备的坐标点 
      dqafyzb: "未选择",//当前设备是否已经选择了坐标点
      allCompanyList: [], // 绑定公司
      allCompany: [],
      permissionsSystemName: [], // 所属系统
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
        value: "id",
        checkStrictly: true,
      },
      companyNameById: {},
      systemNameById: {},
      checkedCom: '', // 绑定公司选择
      checkedSys: '', // 所属系统选择
      checkedSys1: "", //点编辑赋值系统id
      checkedValue1: [], // 选择的公司
      checkedValue2: [], // 选择的权限
      //分页相关数据
      current: 1, //当前页
      limit: 10, //每页显示条数
      total: 0, //数据总数
      videoList: [],
      deviceStaticProperties: {
        deviceInfoId: "",
        videoInfoId1: "",
        videoInfoId2: "",
        videoInfoId3: "",
        videoInfoId4: "",
        videoInfoId5: "",
        videoInfoId6: "",
        videoInfoId7: "",
        videoInfoId8: "",
        videoInfoId9: "",
      },
      selectDeviceTypeInfo: {},

      deviceType: [],
      deviceTypeNameList: [],
      getAllDeviceTypeList: [],

      typeName: "", //设备类型
      typeId: "", //设备类型id
      //页面数据
      deviceInfoList: [], //设备类型集合
      deviceInfo: {
        //当前编辑或添加的设备信息
        id: "",
        typeId: "",
        typeName: "",
        name: [],
        value: [],
        isSyncAdd: false
      },
      rules: {
        id: [{
          required: true,
          message: "请输入设备ID",
          trigger: "blur",
        },],
        typeId: [{
          required: true,
          message: "请输入设备类型ID",
          trigger: "blur",
        },],
        name: [{
          required: true,
          message: "请输入设备静态属性",
          trigger: "blur",
        },],
        value: [{
          required: true,
          message: "请输入设备类型ID",
          trigger: "blur",
        },],
      },
      deviceInfoQuery: {
        //模糊查询条件对象
        id: "",
        typeName: '',
        ciId: '',
      },
      deviceStaticNameTemp: "", //追加静态属性
      valNameTemp: "", //追加动态属性

      //标志位
      loading: true,
      dialogVisible: false, //添加、编辑弹框的标志位
      addOrEditFlag: "add", //添加或者编辑的标志位  add是添加   edit是编辑
      videoDialogVisible: false, //绑定视频标志位
      playerDialogVisible: false, //视频播放器标志位
      importData: {
        //导入数据 工具对象
        butTextImport: "批量导入",
        butTextExport: "批量导出",
        iconImport: "el-icon-download",
        iconExport: "el-icon-upload2",
        disabledImport: false,
        disabledExport: false,
      },
      baseUrl: baseDate.baseUrl,
      proList: [],//动态属性
      dateList: [], //折线图时间显示数组
      valueList: [], //折线图值显示数组
      hValue: "", //折线图高阈值显示
      lValue: "", //折线图低阈值显示
      chartTitle: "",//折线图标题
      unit: "",//折线图单位显示
      isShowTu: false,//是否显示折线图,
      showChartDeviceId: "",//显示折线图的设备id
      showChartDeviceTypeId: "",//显示折线图的设备类型id
      //上传图片
      deviceInfoIcon: "",
      file: {},
      imgFlag: false,
      isFileExist: false,//判断是否选择设备图片文件

      isInvoke: true,//是否布防

      // userName: '',
      userRightList: [],

      //rtsp视频相关参数
      rtspValue: '', //流地址
      playUrl: '',   //视频播放流地址 
      videoIp: '',   //视频服务所在IP
      // vServerPort:'', //视频服务端口目前写死
      cameraIp: '',    //摄像头ip
      cameraUsername: '',  //摄像头用户名
      cameraPassword: '',  //摄像头密码
      cameraManufacturer: '',    //摄像头厂家

      //flv播放
      gatewayIp: '', //网关ip
      gatewayPort: '',   //网关端口
      channel: '',   //视频通道

      
    };
  },
  watch:{
    // 拿到最新的数据
    checkedCom(a){
      this.getDeviceTypeByciId(a[0])
    }
  },
  created() {
    this.getUserRight()
    this.getAllDeviceType()
    this.jurisSysName()
    this.getAllCompanyList()
    this.getCompanyInfo()
    this.getAllDeviceTypeInfo()

    // if (dataStorage.getBaseData("deviceInfomanagemtrelode") == 0 || dataStorage.getBaseData("deviceInfomanagemtrelode") == null) {
    //   window.location.reload()
    //   dataStorage.setBaseData("deviceInfomanagemtrelode", 1)
    // } else {
    //   dataStorage.setBaseData("deviceInfomanagemtrelode", 0)
    // }

  },
  computed: {
    ...mapState({
      userName: state => state.login.userName,
      lists: state => state.login.userRightList,
    })
  },
  methods: {
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('设备编号')
      } else {
        this.tableTitle = value
      }
    },
    selectDeviceType() {
      this.deviceInfoQuery.typeName = this.deviceTypeName
    },
    // 查所有设备类型
    async getAllDeviceType() {
      const { data: res } = await DeviceType.getAllDeviceType();
      this.getAllDeviceTypeList = res.data.list;
      this.deviceType = res.data.list;
    },

    // 获取用户权限
    async getUserRight() {
      this.userRightList = this.lists
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < this.userRightList.length; i++) {
          if (this.userRightList[i].menuName == "deviceInfoManagement") {
            this.userRightList = this.userRightList[i]
          }
        }
      }
      if (this.user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }
      console.log(this.userRightList);
    },
    // 获取用户名
    // getUsers() {
    //   user.loginUserInfo().then(res => {
    //     this.userName = res.data.data.userName
    //     this.getUserRight(this.userName)
    //   })
    // },


    //查询设备类型信息
    getAllDeviceTypeInfo() {
      //获取所有设备类型
      DeviceType.getDeviceTypeList(1, 999, {
        name: "",
      }).then((resp) => {
        if (resp) {
          this.deviceType = resp.data.data.rows;
        }
      });
    },
    // 通过公司ID查询公司下的设备类型(传参为watch调用)
    async getDeviceTypeByciId(val) {
      // 接收val值的中间量
      let param = ''
      if(val){
        param = val
      } else {
        param = this.checkedValue1
      }
      const { data: res } = await DeviceType.getDeviceTypeByCiIds(param);
      let newArr = [];
      for (let i = 0; i < this.deviceType.length; i++) {
        for (let j = 0; j < res.data.deviceTypeSet.length; j++) {
          if (this.deviceType[i].id == res.data.deviceTypeSet[j]) {
            newArr.push(this.deviceType[i])
          }
        }
      }
      this.deviceTypeNameList = newArr;
    },

    // #######################################################################

    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        this.allCompany = res.data.data.list;
        console.log(this.allCompany);
        for (let i in res.data.data.list) {
          this.companyNameById[res.data.data.list[i]["id"]] = res.data.data.list[i]["name"]
        }
        //开局查看所有设备数据信息,在这里调用是因为需要先查询出公司再进行id转换
        this.getDeviceInfoList()
      });
    },
    closeDialog() {
      this.activeName = "first";
    },

    // 设备信息
    lookMore(row) {
      this.$router.push({
        path: '/aSingleDeviceInformation',
        query: {
          deviceId: row.id,
        }
        // name:"aSingleDeviceInformation",
        // params:{
        //   rowInfo: JSON.stringify(row)
        // }
      })

    },
    switchTab() {
      if (this.activeName == "second") {
        this.showChart = true;
      }
    },
    showCharts(row) {
      this.proList = [];
      this.chartTitle = "";
      this.isShowTu = false
      this.showCharDeviceId = row.id
      this.showChartDeviceTypeId = row.typeId
      deviceInfo.getDynamicPro(row.typeId)
        .then((res) => {
          let _this = this;
          for (const i in res.data.data.list) {
            if ((res.data.data.list[i].indexOf("值") != -1
              || res.data.data.list[i].indexOf("电压") != -1
              || res.data.data.list[i].indexOf("电流") != -1
            )
              && res.data.data.list[i].indexOf("状态") == -1
              && res.data.data.list[i].indexOf("阈值") == -1) {
              this.proList.push(res.data.data.list[i]);
            }
          }
          if (this.proList.length == 1 || this.proList.length > 1) {
            deviceInfo.getDayValue(
              row.id,
              "",
              "",
              row.typeId,
              row.id + ".value." + this.proList[0]
            ).then((res) => {
              if (res.data.message == "不显示") {
                _this.isShowTu = false;
              } else {
                _this.dateList = res.data.data.dateList;
                _this.valueList = res.data.data.valueList;
                if (res.data.data.hValue != undefined && res.data.data.hValue != null) {
                  _this.hValue = res.data.data.hValue;
                  _this.lValue = res.data.data.lValue;
                }
                if (res.data.data.unit != "" && res.data.data.unit != undefined) {
                  this.unit = res.data.data.unit
                }
                this.chartTitle = this.proList[0]
                _this.isShowTu = true
              }
            })
          }
        })
    },
    //切换显示的折线图
    switchChart(item) {
      for (let i = 0; i < this.proList.length; i++) {
        if (item == this.proList[i]) {
          document.getElementById('item' + i).style.background = 'rgba(122, 184, 247, 0.4)'
        } else {
          document.getElementById('item' + i).style.background = 'none'
        }
      }
      this.isShowTu = false;
      this.chartTitle = item
      deviceInfo.getDayValue(
        this.showCharDeviceId,
        "",
        "",
        this.showChartDeviceTypeId,
        this.showCharDeviceId + ".value." + item
      ).then((res) => {
        if (res.data.message == "不显示") {
          this.isShowTu = false;
        } else {
          this.dateList = res.data.data.dateList;
          this.valueList = res.data.data.valueList;
          if (res.data.data.hValue != undefined && res.data.data.hValue != null) {
            this.hValue = res.data.data.hValue;
            this.lValue = res.data.data.lValue;
          }
          if (res.data.data.unit != "" && res.data.data.unit != undefined) {
            this.unit = res.data.data.unit
          }
          this.isShowTu = true
        }
      })
    },
    // 查询公司结构
    getAllCompanyList() {
      companySystem.getAllCompanyStructure().then((res) => {
        // 公司结构集合
        this.allCompanyList = res.data.data.list;
      });
    },

    // 获取权限系统名称
    jurisSysName() {
      noticeSysManagement.getNotifyBigSystemById().then((res) => {
        this.systemNameById = {}
        this.permissionsSystemName = res.data.data.list;
        for (let i in this.permissionsSystemName)
          this.systemNameById[this.permissionsSystemName[i].id] = this.permissionsSystemName[i].bigSystemName
      });
    },
    checkedCompany1(value) {
    this.deviceInfoQuery.ciId = value[value.length - 1] 
    //   if (this.$refs.cascader1.getCheckedNodes()[0] == undefined) {
    //     this.deviceInfoQuery.ciId = '';
    //   } else {
    //     this.deviceInfoQuery.ciId = this.$refs.cascader1.s()[0].data.id;
    //   }
    //   console.log(this.$refs.cascader1.getCheckedNodes()[0]);
    //   this.$refs.cascader1.dropDownVisible = false;
    },
    // 添加设备位置
    addMapPosition() {
      this.rowId = this.deviceInfo.id
      if (this.rowId == '') {
        this.$message({
          type: "warning",
          message: "请填写设备ID!"
        })
      } else {
        this.deviceMap = true
      }
    },
    // 添加地图点击提交坐标时，获取子组件中的参数
    send(value) {
      if (value) {
        this.deviceMap = false
      } else {
        this.deviceMap = true
      }
    },

    // 选择的公司
    checkedCompany() {
      if (this.$refs.cascader.getCheckedNodes().length != 0) {
        this.checkedValue1 = this.$refs.cascader.getCheckedNodes()[0].data.id;
        this.getDeviceTypeByciId()
      } else {
        this.deviceTypeNameList = []
      }
      //清空已选择的设备类型
      this.typeName = ""
      // 选择后让选择框消失
      this.$refs.cascader.dropDownVisible = false;

    },

    //  选择的系统
    checkedSystem(val) {
      for (let i = 0; i < this.deviceInfo.name.length; i++) {
        if (this.deviceInfo.name[i] == "所属系统") {
          this.deviceInfo.value[i] = this.checkedSys
          break;
        }
        if (i == this.deviceInfo.name.length - 1) {
          this.deviceInfo.name.push("所属系统")
          this.deviceInfo.value.push(this.checkedSys)
        }
      }
    },

    // *----------------------------------------*

    goSelect() {
      this.$router.push("/Select");
    },
    toCRT(row) {

      crtDeviceCoordinate.getCoordinateByDeviceId(row.id).then((res) => {
        if (res.data.message != "失败") {
          this.$router.push({
            name: "CRTShow",
            params: {
              CRTId: res.data.data.data.crtPictureId,
              deviceId: res.data.data.data.deviceInfoId,
            },
          });
        } else {

          this.$message({
            type: "warning",
            message: "该设备尚未添加点位！",
          });
        }
      });
    },

    toHistory(value) {
      this.$router.push({
        path: "/History",
        query: {
          deviceId: value.id
        }
      });
    },

    refreshVideoList(val) {
      // for (let index = 0; index < this.videoList.length; index++) {
      //   if (this.videoList[index].id == val) {
      //     this.videoList.splice(index, 1);
      //     continue;
      //   }
      // }
    },
    getAllVideo() {
      deviceInfo
        .getAllVideo(this.deviceStaticProperties.deviceInfoId)
        .then((res) => {
          this.videoList = res.data.data.list;
        });
    },
    clearDeviceStaticProperties() {
      this.deviceStaticProperties = {
        deviceInfoId: "",
        videoInfoId1: "",
        videoInfoId2: "",
        videoInfoId3: "",
        videoInfoId4: "",
        videoInfoId5: "",
        videoInfoId6: "",
        videoInfoId7: "",
        videoInfoId8: "",
        videoInfoId9: "",
      };
    },
    realBindVideo() {
      // if (this.deviceStaticProperties.videoInfoId1 == "") {
      //     this.$message({
      //         type: "error", 
      //         message: "请至少选择一个视频设备后再进行绑定！",
      //     });
      // }
      //  else {
      deviceInfo.bindVideo(this.deviceStaticProperties).then((res) => {
        this.$message({
          type: res.data.success ? "success" : "error",
          message: res.data.message,
        });
        if (res.data.success) {
          this.videoDialogVisible = false;
          this.getDeviceInfoList();
        }
      });
      // }
    },
    //视频
    async showVideo(row) {

      if (row.typeId == "YSVideo") {
        this.$router.push({
          path: '/VideoPlayback',
          query: {
            rowInfo: row,
          }
        });
      } 
      else if (row.isBindYSVideo) {
          this.$router.push({
            path: '/VideoPlayback',
            query: {
              rowInfo: row,
            }
          });
        } 
      else if (row.typeId == "VideoRTSP") {
            for (let i in row.device) {
              if (row.device[i].proName == "rtsp") {
                this.rtspValue = row.device[i].value;
              }
              if (row.device[i].proName == "视频服务IP") {
                this.videoIp = row.device[i].value;
              }
              // //端口一般固定,这里先注掉写死,有需要再加
              // if (row.device[i].proName == "视频服务端口"){ 
              //   this.vServerPort = row.device[i].value;
              // }
              if (row.device[i].proName == "摄像头IP") {
                this.cameraIp = row.device[i].value;
              }
              if (row.device[i].proName == "摄像头账号") {
                this.cameraUsername = row.device[i].value;
              }
              if (row.device[i].proName == "摄像头密码") {
                this.cameraPassword = row.device[i].value;
              }
              if (row.device[i].proName == "厂家") {
                this.cameraManufacturer = row.device[i].value;
              }
            }

            //跳转播放页
            // this.$router.push({
            //   path: '/Media',
            //   query: {
            //   rtsp: this.rtspValue
            //   }
            // });

            //当前页弹窗播放
            if (this.cameraManufacturer == "海康") {
              console.log("海康");
              this.rtspValue = "rtsp://" + this.cameraUsername + ":" + this.cameraPassword + "@" + this.cameraIp + ":554/ch1/main/av_stream"
              this.playerDialogVisible = true;
              console.log(this.rtspValue);
              this.playUrl = "ws://" + this.videoIp + ":50031/live?url=" + this.rtspValue + "&&&ffmpeg=true"
              this.$nextTick(() => {
                console.log(this.$refs["video0"]);
                this.$refs["video0"].createPlayer(this.playUrl, false, "0");
              })
            } else if (this.cameraManufacturer == "大华") {
                this.rtspValue = "rtsp://" + this.cameraUsername + ":" + this.cameraPassword + "@" + this.cameraIp + ":554/cam/realmonitor?channel=1&subtype=0"
                this.playerDialogVisible = true;
                this.playUrl = "ws://" + this.videoIp + ":50031/live?url=" + this.rtspValue + "&&&ffmpeg=true"
                this.$nextTick(() => {
                  console.log(this.$refs["video0"]);
                  this.$refs["video0"].createPlayer(this.playUrl, false, "0");
                })
              } else {
                this.$message({
                  type: "error",
                  message: "获取rtsp失败!",
                });
              }
            

          } 
      else if (row.isBindRTSP) {
              //先获取设备绑定的视频的设备id
              for (let i in row.device) {
                let str = row.device[i].value
                if (str.search("RTSP") != -1) {
                  await this.getRTSPByDeviceId(str)
                }
              }

              //跳转播放页
              // this.$router.push({
              // path: '/Media',
              // query: {
              // rtsp: this.rtspValue,
              // }
              // });

              //当前页弹窗播放
              if (this.playUrl != "") {
                this.playerDialogVisible = true;
                // this.playUrl = "ws://"+this.videoIp+"/live?url="+this.rtspValue+"&&&ffmpeg=true"
                this.$nextTick(() => {
                  console.log(this.$refs["video0"]);
                  this.$refs["video0"].createPlayer(this.playUrl, false, "0");
                })
              } else {
                this.$message({
                  type: "error",
                  message: "获取rtsp失败!",
                });
              }
            } 
      else if (row.typeId == "videoFlv") {
        console.log(row.typeId);
                
                console.log(this.playUrl);
                //根据设备Id获取绑定视频的Id、视频绑定的网关IP、端口、视频通道。。
              await this.getVideoMessageByVideoNameByName(row.id)
              //当前页弹窗播放
              if (this.playUrl != "") {
                this.playerDialogVisible = true;
                this.$nextTick(() => {
                  console.log(this.$refs["video0"]);
                  this.$refs["video0"].createPlayer(this.playUrl, false, "0");
                })
            }
          }
      else if (row.isBindFlv) {
              //根据设备Id获取绑定视频的Id、视频绑定的网关IP、端口、视频通道。。
              await this.getBindVideoMessageByDeviceId(row.id)
              //当前页弹窗播放
              if (this.playUrl != "") {
                this.playerDialogVisible = true;
                this.$nextTick(() => {
                  console.log(this.$refs["video0"]);
                  this.$refs["video0"].createPlayer(this.playUrl, false, "0");
                })
              } else {
                this.$message({
                  type: "error",
                  message: "获取Flv地址失败!",
                });
              }
            }
      else if (row.videoUrl) {
                window.location.href = row.videoUrl;
            } 
      else {
                this.$message({
                  type: "error",
                  message: "该设备未绑定任何摄像头！",
                });
              }
 
    },
    addNewBindVideo(row) {
      this.deviceStaticProperties = {
        deviceInfoId: row.id,
        videoInfoId1: row.videoInfoId1,
        videoInfoId2: row.videoInfoId2,
        videoInfoId3: row.videoInfoId3,
        videoInfoId4: row.videoInfoId4,
        videoInfoId5: row.videoInfoId5,
        videoInfoId6: row.videoInfoId6,
        videoInfoId7: row.videoInfoId7,
        videoInfoId8: row.videoInfoId8,
        videoInfoId9: row.videoInfoId9,
      };
      this.deviceStaticProperties.deviceInfoId = row.id;
      this.videoDialogVisible = true;
      this.getAllVideo();
    },
    //多条件分页查询设备类型
    getDeviceInfoList() {
      var that = this;
      that.deviceInfoQuery.id;
      deviceInfo
        .getDeviceInfoList(this.current, this.limit, this.deviceInfoQuery)
        .then((res) => {
          this.total = res.data.data.total;
          this.deviceInfoList = res.data.data.rows;
          //   let newArr = this.deviceInfoList;


          for (let i = 0; i < this.deviceInfoList.length; i++) {
            // 把deviceInfoList下的name数组里的属性添加到deviceInfoList集合里
            if (this.deviceInfoList[i].name != null) {
              for (let j = 0; j < this.deviceInfoList[i].name.length; j++) {
                if (this.deviceInfoList[i].name[j] == "安装位置") {
                  this.addressValue = this.deviceInfoList[i].value[j]
                } else if (this.deviceInfoList[i].name[j] == "绑定公司") {
                  this.companyValue = this.deviceInfoList[i].value[j]
                } else if (this.deviceInfoList[i].name[j] == "所属系统") {
                  this.systemValue = this.deviceInfoList[i].value[j]
                }
              }
            }
            // 公司id转换为文字
            for (let i = 0; i < this.allCompany.length; i++) {
              if (this.allCompany[i].id == this.companyValue) {
                this.companyValue = this.allCompany[i].name
                // 处理字符 去掉>
                let str = this.companyValue
                str = str.replace(/>/g, '')
                this.companyValue = str
              }
            }
            // 系统id转换为文字
            for (let i = 0; i < this.permissionsSystemName.length; i++) {
              if (this.permissionsSystemName[i].id == this.systemValue) {
                this.systemValue = this.permissionsSystemName[i].bigSystemName
              }
            }
            this.deviceInfoList[i].addressValue = this.addressValue
            this.deviceInfoList[i].systemValue = this.systemValue
            this.deviceInfoList[i].companyValue = this.companyValue
          }

          if (res.data.success) {
            this.loading = false;
          }
        });
    },
    //多条件分页查询设备类型
    getDeviceInfoList1() {
      deviceInfo.getDeviceInfoList(1, this.limit, this.deviceInfoQuery).then((res) => {
          this.total = res.data.data.total;
          this.deviceInfoList = res.data.data.rows;
          this.current = 1;
            for (let i = 0; i < this.deviceInfoList.length; i++) {
            // 把deviceInfoList下的name数组里的属性添加到deviceInfoList集合里
            if (this.deviceInfoList[i].name != null) {
              for (let j = 0; j < this.deviceInfoList[i].name.length; j++) {
                if (this.deviceInfoList[i].name[j] == "安装位置") {
                  this.addressValue = this.deviceInfoList[i].value[j]
                } else if (this.deviceInfoList[i].name[j] == "所属系统"){
                  this.systemValue = this.deviceInfoList[i].value[j]
                } else if (this.deviceInfoList[i].name[j] == "绑定公司"){
                  this.companyValue = this.deviceInfoList[i].value[j]
                }
              }
            }
            // 公司id转换为文字
            for (let i = 0; i < this.allCompany.length; i++) {
              if (this.allCompany[i].id == this.companyValue) {
                this.companyValue = this.allCompany[i].name
              }
            }
            // 系统id转换为文字
            for (let i = 0; i < this.permissionsSystemName.length; i++) {
              if (this.permissionsSystemName[i].id == this.systemValue) {
                this.systemValue = this.permissionsSystemName[i].bigSystemName
              }
            }
            this.deviceInfoList[i].addressValue = this.addressValue
            this.deviceInfoList[i].systemValue = this.systemValue
            this.deviceInfoList[i].companyValue = this.companyValue
          }

          if (res.data.success) {
            this.loading = false;
          }
        });
        console.log(this.deviceInfoList);
    },
    //验证
    beforeAvatarUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isSVG = file.type === "image/svg+xml";
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!(isJPG || isPNG || isSVG || isJPEG)) {
        this.$message.error("上传图标只能是 JPG、PNG或SVG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图标大小不能超过 1MB!");
        return (isJPG || isPNG || isSVG) && isLt2M;
      }
    },
    //上傳设备类型图标文件选择后触发
    fileChange(e) {
      let _this = this;
      let files = e.target.files[0]; // 获取上传内容
      this.file = files;

      let fileReader = new FileReader();
      let type = "default";

      this.beforeAvatarUpload(files);
      //判断文件类型
      if (/image/.test(files.type)) {
        fileReader.readAsDataURL(files);
        type = "image";
      } else {
        fileReader.readAsText(files, "utf-8");
        type = "text";
      }
      //文件加载出错
      fileReader.onerror = function (err) {
        console.log(err);
      };
      fileReader.onload = () => {
        _this.deviceInfoIcon = fileReader.result;
        this.imgFlag = true;
      };
      this.isFileExist = true;
    },
    //添加或者编辑设备类型信息
    async addOrEditDeviceInfo() {

      let flagThis = false;
      if (
        this.deviceInfo.id != "" &&
        this.deviceInfo.id != null &&
        this.deviceInfo.typeId != "" &&
        this.deviceInfo.typeId != null &&
        this.deviceInfo.name.length == this.deviceInfo.value.length
      ) {
        flagThis = true;
        for (let i = 0; i < this.deviceInfo.name.length; i++) {
          if ((this.deviceInfo.name[i] == "上级设备" || this.deviceInfo.name[i] == "设备名称") && this.deviceInfo.value[i] == "") {
            this.deviceInfo.name.splice(i, 1)
            this.deviceInfo.value.splice(i, 1)
            i--;
            continue;
          }
          if (this.deviceInfo.name[i] == "" || this.deviceInfo.value[i] == "") {
            flagThis = false;
          }
        }
      }
      if (!flagThis) {
        alert("请填写全部信息");
        return null;
      }

      //添加布防状态
      let isHaveBufang = false;
      console.log(this.deviceInfo)
      for (const i in this.deviceInfo.name) {
        if (this.deviceInfo.name[i] == "布防状态") {
          if (this.isInvoke) {
            this.deviceInfo.value[i] = "1"
          } else {
            this.deviceInfo.value[i] = "0"
          }
          isHaveBufang = true
        }
      }
      if (isHaveBufang == false) {
        if (this.isInvoke) {
          this.deviceInfo.name.push("布防状态")
          this.deviceInfo.value.push("1")
        } else {
          this.deviceInfo.name.push("布防状态")
          this.deviceInfo.value.push("0")
        }
      }
      //判断是否同步添加aep
      if (this.deviceInfo.isSyncAdd) {
        this.deviceInfo.isSyncAdd = "true"
      } else {
        this.deviceInfo.isSyncAdd = "false"
      }

      if (this.addOrEditFlag == "add") {
        //实例化FormData对象用于传输图片文件和图片名称
        const formData = new FormData();
        formData.append("multipartFile", this.file);
        formData.append("id", this.deviceInfo.id);
        formData.append("typeId", this.deviceInfo.typeId);
        //添加设备
        const { data: res } = await deviceInfo.addDeviceInfo(this.deviceInfo)

        //存入设备图片
        if (this.isFileExist) {
          const { data: ress } = await deviceInfo.saveDeviceImg(formData)
          console.log(ress)
        }
        if (res.success) {
          // 添加二维码设备
          if (this.selectDeviceTypeInfo.id.indexOf('QrCode') != -1) {
            console.log(this.selectDeviceTypeInfo);
            const { data: res } = await QrCodeDeviceInfo.saveQrCodeImg(this.deviceInfo.id)
            console.log(res);
          }


          //提示信息
          this.$message({
            type: "success",
            message: res.message,
          });


          console.log(this.deviceInfo);
          let companyId = ''
          for (let i = 0; i < this.deviceInfo.name.length; i++) {
            if (this.deviceInfo.name[i] == "绑定公司") {
              companyId = this.deviceInfo.value[i]
            }
          }
          let id = this.deviceInfo.id
          let typeId = this.deviceInfo.typeId
          let ciId = companyId
          let type = 'add'
          console.log(id, typeId, ciId, type);

          this.$refs.uploadInput.value = null//清除文件信息
          this.isFileExist = false;
          //回到页面并刷新
          this.dialogVisible = false; //关闭弹窗
          this.clearDeviceInfo(); //清空设备类型信息对象数据
          this.getDeviceInfoList(); //刷新页面数据

          // 添加完成后回调
          await DeviceType.updateDeviceNumber(id, typeId, ciId, type)

        } else {
          //提示信息
          this.$message({
            type: "warning",
            message: res.message,
          });
        }
      } else {
        //实例化FormData对象用于传输图片文件和图片名称
        const formData = new FormData();
        formData.append("multipartFile", this.file);
        formData.append("id", this.deviceInfo.id);
        formData.append("typeId", this.deviceInfo.typeId);
        formData.append("typeName", this.deviceInfo.typeName);
        formData.append("name", this.deviceInfo.name);
        formData.append("value", this.deviceInfo.value);
        // formData.append("deviceTypeIcon", this.deviceTypeIcon);

        const { data: res } = await deviceInfo.updataDeviceInfo(this.deviceInfo)
        if (res.success) {
          //存入设备图片
          if (this.isFileExist) {
            const { data: ress } = await deviceInfo.saveDeviceImg(formData)
          }
          //提示信息
          this.$message({
            type: "success",
            message: res.message,
          });
          this.$refs.uploadInput.value = null//清除文件信息
          this.isFileExist = false;
          //回到页面并刷新
          this.dialogVisible = false; //关闭弹窗
          this.clearDeviceInfo(); //清空设备类型信息对象数据
          this.getDeviceInfoList(); //刷新页面数据
        } else {
          //提示信息
          this.$message({
            type: "warning",
            message: res.message,
          });
        }
      }
    },


    //根据设别类型id删除设备类型信息
    handleRemove(id, row) {
      console.log(id, row);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {

        //点击确定，指定then方法
        deviceInfo.removeDeviceInfo(id).then((res) => {
          console.log(res);
          if (res.data.message == "成功" && row.typeId.indexOf('QrCode') != -1) {
            this.deleteQrCode(id)
          }
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getDeviceInfoList();
          // 添加完成后回调
          if (res.data.message == "成功") {
            let companyId = ''
            for (let i = 0; i < row.name.length; i++) {
              if (row.name[i] == "绑定公司") {
                companyId = row.value[i]
              }
            }
            DeviceType.updateDeviceNumber(id, row.typeId, companyId, 'delete')
          }
        });
      });
    },

    // 删除二维码设备
    deleteQrCode(id) {
      console.log(id);
      QrCodeDeviceInfo.deleteQrCodeImg(id).then((res) => {
        //回到页面并刷新
        // this.getDeviceInfoList();
      });
    },


    //添加设备类型信息弹框
    handelAdd() {
      this.isInvoke = true
      this.isDisplay = false    //是否展示AEP开关按钮
      this.file = {}//清空文件信息
      //   this.setingMap = false;
      var that = this;

      that.zbd = {
        lat: null,
        lng: null
      },//提交设备的坐标点 
        that.dqafyzb = "未选择"

      that.checkedSys = ""

      that.typeName = "";
      that.selectDeviceTypeInfo = {};
      that.deviceInfo.name = [];
      that.deviceInfo.value = [];
      this.checkedCom = ""
      this.checkedSys = ""

      this.dialogVisible = true;
      this.addOrEditFlag = "add";
    },
    //修改设备类型信息弹框数据加载
    // closeMapBox() {
    //   // deviceMap
    //   console.log(this.rowId);

    // },
    handleEdit(row) {
      this.isDisplay = false   //是否展示AEP开关按钮
      this.isInvoke = true
      this.file = {}//清空文件信息
      this.rowId = row.id;
      console.log(row)
      this.clearDeviceInfo()

      var that = this;
      that.typeName = row.typeName;
      let a = "";
      if (row.name != null)
        for (let i = 0; i < row.name.length; i++) {
          if (row.name[i] == "所属系统" || row.name[i] == "绑定公司" || row.name[i] == "设备图片" || row.name[i] == "布防状态") {
            if (row.name[i] == "所属系统") {
              that.checkedSys = this.systemNameById[row.value[i]]
              that.checkedSys1 = row.value[i]
            } else if (row.name[i] == "绑定公司") {
              that.checkedCom = row.value[i]
              that.checkedValue1 = row.value[i]
              that.getDeviceTypeByciId();
            } else if (row.name[i] == "设备图片") {
              // a = that.baseUrl+row.value[i]
              a = row.value[i]
            } else if (row.name[i] == "布防状态") {
              if (row.value[i] == "1") {
                this.isInvoke = true
              } else if (row.value[i] == "0") {
                this.isInvoke = false
              }
            }
          }
        }

      that.selectDeviceTypeInfo = {
        properties: row.name,
        id: row.typeId,
        name: row.typeName,
      };
      this.deviceInfoIcon = a
      this.deviceInfo = {
        id: row.id,
        typeId: row.typeId,
        name: row.name,
        value: row.value,
      };
      this.dialogVisible = true;
      this.addOrEditFlag = "edit";

      that.sxsbshlw = false
      if (that.deviceInfo.typeId.length > 2 && "GR" == that.deviceInfo.typeId.substring(that.deviceInfo.typeId.length - 2)) {
        that.sxsbshlw = false;

      }
    },
    removeLastOneFromProperties() {
      this.deviceInfo.name.pop();
      this.deviceStaticNameTemp = "";
    },
    //追加静态属性的值
    // addToVal() {
    //     this.deviceInfo.value.push(this.valNameTemp);

    //     this.valNameTemp = "";
    // },
    removeLastOneFromVal() {
      this.deviceInfo.value.pop();
      this.valNameTemp = "";
    },

    //清空设备类型信息对象
    clearDeviceInfo() {
      if (this.isFileExist) {
        this.$refs.uploadInput.value = null//清除文件信息
        this.isFileExist = false;
      }
      this.deviceInfoIcon = "";
      this.deviceInfo = {
        id: "",
        typeId: "",
        name: [],
        value: [],
        typeName: "",
      };
      this.checkedSys = ""
      this.checkedCom = ""
      this.deviceTypeNameList = []//清空设备类型集合
      this.checkedSys1 = ""//清空系统id
      this.imgFlag = false;
    },

    //返回上一级
    goBack() {
      window.history.back();
    },
    async buiding(selectInfo) {
      console.log(selectInfo);
      if (selectInfo.id.indexOf('DX') != -1) {
        this.isDisplay = true;
      } else {
        this.isDisplay = false;

      }
      var that = this;
      that.deviceInfo.typeId = "";
      that.deviceInfo.name = [];
      that.deviceInfo.value = [];
      that.selectDeviceTypeInfo = selectInfo;
      that.sxsbshlw = false
      if (that.selectDeviceTypeInfo != "" && that.selectDeviceTypeInfo != null && that.selectDeviceTypeInfo != undefined) {
        that.typeName = that.selectDeviceTypeInfo.name;
        if (that.deviceInfo.typeId.length > 2 && "GR" == that.deviceInfo.typeId.substring(that.deviceInfo.typeId.length - 2)) {
          that.sxsbshlw = false;
        }
        that.deviceInfo.typeId = that.selectDeviceTypeInfo.id;
        if (that.selectDeviceTypeInfo.properties != null) {
          that.selectDeviceTypeInfo.properties.forEach((element) => {
            that.deviceInfo.name.push(element);
            this.deviceInfo.value.push("")
          });
        } else {
          const { data: res } = await DeviceType.getStaticPro(that.deviceInfo.typeId)
          that.selectDeviceTypeInfo.properties = []
          res.data.list.forEach(element => {
            that.selectDeviceTypeInfo.properties.push(element)
            that.deviceInfo.name.push(element);
            this.deviceInfo.value.push("")
          })
          that.$set(that.selectDeviceTypeInfo)
          that.$set(that.deviceInfo)
        }
        this.deviceInfo.name.push("绑定公司")
        for (let i = 0; i < this.deviceInfo.name.length; i++) {
          if (this.deviceInfo.name[i] == "绑定公司") {
            this.deviceInfo.value.push(this.checkedValue1)
            break;
          }
        }
        this.deviceInfo.name.push("所属系统")
        for (let i = 0; i < this.deviceInfo.name.length; i++) {
          if (this.deviceInfo.name[i] == "所属系统") {
            if (this.checkedSys1 == "" || this.checkedSys1 == null || this.checkedSys1 == undefined) {
              this.deviceInfo.value.push("无")
            } else {
              this.deviceInfo.value.push(this.checkedSys1)
            }
            break
          }
        }
      }
    },
    dialogVisibleFun() {
      if (this.isFileExist) {
        this.$refs.uploadInput.value = null//清除文件信息
        this.isFileExist = false;
      }
      var that = this;
      that.typeName = "";
      that.selectDeviceTypeInfo = {};
      that.dialogVisible = false;
      that.deviceInfo.name = [];
      that.deviceInfo.value = [];
      this.isDisplay = false;
    },
    beforeUpload() {
      //上传文件之前的钩子
      this.importData.iconImport = "el-icon-loading";
      this.importData.butTextImport = "正在导入";
      this.importData.disabledImport = true;
    },
    onSuccess(response) {
      //上传文件成功的钩子
      this.importData.disabledImport = false;
      this.importData.iconImport = "el-icon-download";
      this.importData.butTextImport = "批量导入";
      if (response.code == 20000) {
        this.$message.success(response.message);
        this.getDeviceInfoList()//刷新页面数据
        DeviceType.startDeviceNumber()
      }
      if (response.code == 20001) {
        this.$message.error(response.message);
      }
      this.init();
    },
    onError() {
      //上传文件失败的钩子
      this.importData.disabledImport = false;
      this.importData.iconImport = "el-icon-download";
      this.importData.butTextImport = "批量导入";
      this.$message.error("导入失败！");
    },
   async exportAll() {
      this.importData.butTextExport = "正在下载";
      this.importData.iconExport = "el-icon-loading";
      this.importData.disabledExport = true;
     deviceInfo.deriveTable().then((res) => {
      this.newDeviceInfoList = res.data.data.deviceInfoList;
          //公司id转公司名称
            for (let i = 0; i < this.allCompany.length; i++) {
              for(let j = 0;j < this.newDeviceInfoList.length;j++){
                if (this.allCompany[i].id == this.newDeviceInfoList[j].companyValue) {
                this.newDeviceInfoList[j].companyValue = this.allCompany[i].name
            }
              }   
          }
          console.log(this.newDeviceInfoList);
          if (res.data.success) {
            this.loading = false;
          }
          require.ensure([], () => {
            const tHeader = [
              "设备编号",
              "设备类型",
              "安装位置",
              "单位",
              '创建时间'
            ]; // 对应表格输出的中文title
            const filterVal = [
              "id",
              "name",
              "addressValue",
              "companyValue",
              'create_time'
            ]; // 对应表格输出的数据
            const list = this.newDeviceInfoList; // 表格data

            const data = this.formatJson(filterVal, list);

            export_json_to_excel(tHeader, data, "设备信息"); // 对应下载文件的名字
          });
          this.importData.butTextExport = "批量导出";
          this.importData.iconExport = "el-icon-upload2";
          this.importData.disabledExport = false;
        });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    selectLola() {
      if (this.deviceInfo.id == "" ||
        this.deviceInfo.id == null) {
        alert("请填写设备号")
      } else {
        // 打开设备提交
        this.submitWindows = true
      }
    },
    noselectLola() {
      this.submitWindows = false

    }
    ,
    async getRTSPByDeviceId(deviceId) {
      const res = await deviceInfo.getRTSPByDeviceId(deviceId)
      if (res) {
        // this.rtspValue=res.data.data.rtsp;
        this.playUrl = res.data.data.playUrl;
      }
    },
    //根据设备ID查询绑定的视频id，通道，视频网关ip，网关端口
    async getBindVideoMessageByDeviceId(deviceId) {
      const res = await deviceInfo.getBindVideoMessageByDeviceId(deviceId)
      if (res) {
        //8082是网关推流端口，基本写死，和网关设备上的port（res.data.data.gatewayPort）不是同一属性
        this.playUrl = "http://"+res.data.data.gatewayIp+":8082/"+res.data.data.videoChannel+".flv";
      }
    },
    //根据flv视频设备ID查询通道，视频网关ip，网关端口
    async getVideoMessageByVideoNameByName(deviceId) {
      const res = await deviceInfo.getVideoMessageByVideoNameByName(deviceId)
      if (res) {
        //8082是网关推流端口，基本写死，和网关设备上的port（res.data.data.gatewayPort）不是同一属性
        this.playUrl = "http://"+res.data.data.gatewayIp+":8082/"+res.data.data.videoChannel+".flv";
      }
    },
    //开始播放
    handlePlay() {
      if (this.playUrl) {
        this.$refs[CusPlayer].createPlayer(this.playUrl, true, 0);
      } else {
        this.$message.error("请填写播放地址");
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// .videoClass{
//   position: absolute;
//   bottom: 0;
//   top: 100px;
//   left: 0;
//   right: 0;
// }
.rows {
  margin: 10px 10px 10px 0;
}

/deep/ .el-upload__input {
  display: none !important;
}

// .el-button + .el-button {
//   margin-right: 10px;
// }
.middle {
  display: flex;
  justify-content: space-around;
  width: 18%;
}

.el-table {
  background-color: #bdc0c8;
}

.el-upload__input {
  display: none;
}

/deep/ .el-table thead {
  color: #000;
  font-weight: 700;
}

/deep/.el-dialog__title {
  font-size: 22px;
  color: #7ab8f7;
}

/deep/.el-dialog__title::before {
  content: "丨";
  width: 20px;
  color: #7ab8f7;
  font-weight: 700;
  font-size: 24px;
}

.submitWindowsViewClass {
  z-index: 999;
  width: 600px;
  height: 500px;
  margin-left: -50px;
  margin-top: 50px;
  position: fixed;
  top: 1rem;
  color: #000;
}

/deep/.el-dialog__body {
  margin-left: 10px;
  padding: 10px;
}

/deep/.el-tabs__item {
  margin-left: 10px;
  font-size: 16px;
}

/deep/.el-tabs__item:hover {
  margin-left: 10px;

  color: #7ab8f7;
}

/deep/.el-tabs__item.is-active {
  color: #7ab8f7;
}

/deep/.el-tabs__active-bar {
  display: none;
}

.contant {
  width: 100%;
  height: 450px;
  overflow: scroll;
}

.tips {
  width: 100%;
  font-size: 18px;
  text-align: center;
  margin-top: 15%;
  color: #bdc0c8;
}

.contant-next {
  margin-top: 10px;
}

.contant-init {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 5px;

  .switchBtn {
    box-sizing: border-box;

    div {
      width: 120px !important;
      border: 1px solid #ccc;
      padding: 8px 0;
      margin: 0 5px;
      text-align: center;
      cursor: pointer;
    }

    div:hover {
      background: rgba(122, 184, 247, 0.4);
    }
  }
}

.item {
  margin: 10px 5px;
}
</style>
